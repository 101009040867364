import React from "react";
import { Link } from "react-router-dom";
import { FaUserLock } from "react-icons/fa";
import "./noAccess.scss";

const NoAccess = () => {
  return (
    <div className="no-access-container">
      <div className="container">
        <h1 className="no-access-header">OOPS.</h1>
        <p className="no-access-p">Du saknar behörighet för sidan.</p>
        <div className="btn-div">
          <Link className="no-access-btn" to="/">
            Logga in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
