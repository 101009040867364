import React from "react";
import { Link } from "react-router-dom";
import "./notFound.scss";

const NotFound = () => {
  return (
    <div className="notFound-container">
      <h2 className="notFound-text">404</h2>
      <p>Oops! Något blev fel. Sidan du söker existerar inte.</p>
      <Link className="no-access-btn" to="/">
        Startsidan
      </Link>
    </div>
  );
};

export default NotFound;
