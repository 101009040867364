import {
  useState,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Navigate } from "react-router-dom";
// ### SERVICE ###
import { ServiceResponse } from "../models/serviceResponses/serviceResponse";
import { get } from "../services/ServiceBase";
// ### CONSTANTS ###
import { BLANK_ADMIN_CONTEXT, BLANK_ADMIN_USER } from "../constants/Constants";
// ### COMPONENTS ###
import Loader from "../components/Loader/Loader";
// ### ADMIN INTERFACES ###
import IAdminContextWrapper from "../models/admin/IAdminContextWrapper";
import IAdminContext from "../models/admin/IAdminContext";
import IAdmin from "../models/admin/IAdmin";
import { IProtectedRoutesInterface } from "../models/componentProps/IProtectedRoutesInterface";

interface IAdminContextProviderProps {
  children: ReactNode;
}
// ### CONTEXT ###
const AdminContext = createContext<IAdminContextWrapper>({
  context: BLANK_ADMIN_CONTEXT,
  updateContext: () => {},
  CheckIfAdmin: () => <></>,
});
// ###############
// ### CONTEXT HOOK ###
export function useAdminContext() {
  return useContext(AdminContext);
}
// #####################

const LOGIN_COOKIE_VALIDATION_ROUTE = "Authentication/getlogin";

let initialLoad = true;
// ### ADMIN CONTEXT PROVIDER HOOK###
export const AdminContextProvider = (props: IAdminContextProviderProps) => {
  const [user, setUser] = useState<IAdmin>(BLANK_ADMIN_USER);
  const [isAdmin, setIsAdmin] = useState(false); // If true, the logged in user's cookie is valid. Which means its a valid admin.
  const [finishedLoading, setFinishedLoading] = useState(false);

  function updateContext(changes: Partial<IAdminContext>) {
    // console.log("CONTEXT IS CHANGING: ", changes);
    if (changes.user) setUser(changes.user);
  }

  // ### JSX ELEMENT TO WRAP PROTECTED ADMIN ROUTES WITH ###
  const CheckIfAdmin = useCallback(
    (props: IProtectedRoutesInterface) => {
      if (initialLoad) return <Navigate to="/" />; // If refreshed, navigate back to login screen.
      if (finishedLoading) {
        // When the cookie has been checked, finishLoading becomes true.
        if (isAdmin) return props.children;
        else return <Navigate to={props.redirectTo} />;
      } else {
        return <Loader />;
      }
    },
    [finishedLoading]
  );
  // #######################################################
  // ##### VALIDATE COOKIE #####
  const checkAdminCookie = async () => {
    setFinishedLoading(false);
    try {
      if (user.role !== "Admin") {
        setIsAdmin(false);
        return;
      }
      const res = await get<ServiceResponse<string>>(
        LOGIN_COOKIE_VALIDATION_ROUTE
      );

      if (res.data.success) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } catch (error) {
      console.error((error as Error).message);
      setIsAdmin(false);
    }
    setFinishedLoading(true);
  };
  // ###########################

  // ### WHENEVER THE USER CHANGES, CHECK ITS COOKIE ###
  useEffect(() => {
    if (user.email.length > 0 && user.role.length > 0) {
      // console.log("USER CHANGED");
      checkAdminCookie();
    }
  }, [user]);
  // ###################################################

  // ### ON INITIAL LOAD / REFRESH, RESET USER ###
  useEffect(() => {
    if (initialLoad) {
      // console.log("INITIAL LOAD / REFRESH");
      setUser(BLANK_ADMIN_USER);
      initialLoad = false;
    }
  }, []);
  // #############################################

  return (
    <AdminContext.Provider
      value={{
        context: {
          user,
          isAdmin,
        },
        updateContext,
        CheckIfAdmin,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

// ###################################
