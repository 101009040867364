import "./app.scss";
import { AdminContextProvider } from "./context/AdminContext";
import Router from "./Router";

function App() {
  return (
    <AdminContextProvider>
      <Router />
    </AdminContextProvider>
  );
}

export default App;
