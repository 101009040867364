import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.headers.post['content-type'] = 'application/json';
axios.defaults.headers.get['content-type'] = 'application/json';
axios.defaults.headers.put["content-type"] = "application/json";
axios.defaults.headers.delete["content-type"] = "application/json";


export const post = async <T>(route: string, data: any) => {
  return await axios.post<T>(`${process.env.REACT_APP_BASE_URL + route}`, data);
};

export const put = async <T>(route: string, data: any) => {
  return await axios.put<T>(`${process.env.REACT_APP_BASE_URL + route}`, data);
};

export const get = async <T>(route: string) => {
  return await axios.get<T>(`${process.env.REACT_APP_BASE_URL + route}`);
};

export const Delete = async <T>(route: string) => {
  return await axios.delete<T>(`${process.env.REACT_APP_BASE_URL + route}`);
};
