import IAdmin from "../models/admin/IAdmin";
import IAdminContext from "../models/admin/IAdminContext";
import ICompanyMinimized from "../models/serviceResponses/ICompanyMinimized";
import IPaginatedData from "../models/serviceResponses/IPaginatedData";
import IStudent from "../models/serviceResponses/IStudent";
import IUser from "../models/serviceResponses/IUser";
import IUserLowerCase from "../models/serviceResponses/IUserLowerCase";
import { ISearchOptions } from "../services/Search.service";

export const DEFAULT_PAGE_START = 1;
export const DEFAULT_PAGE_CAPACITY = 20;

export const BLANK_SEARCH_OPTIONS: ISearchOptions = {
  firstName: "",
  lastName: "",
  email: "",
  id: "",
  pageNumber: 1,
  pageSize: 10,
  tags: [],
  type: "Student",
};

export const BLANK_PAGINATED_STUDENTS: IPaginatedData<IStudent[]> = {
  firstPage: "",
  lastPage: "",
  pageNumber: 1,
  pageSize: 20,
  nextPage: "",
  previousPage: "",
  totalPages: 0,
  totalRecords: 0,
  data: [],
};
export const BLANK_PAGINATED_COMPANIES: IPaginatedData<ICompanyMinimized[]> = {
  firstPage: "",
  lastPage: "",
  pageNumber: 1,
  pageSize: 20,
  nextPage: "",
  previousPage: "",
  totalPages: 0,
  totalRecords: 0,
  data: [],
};
export const BLANK_PAGINATED_DATA = {
  pageNumber: 1,
  pageSize: 10,
  firstPage: "",
  lastPage: "",
  totalPages: 0,
  totalRecords: 0,
  nextPage: "",
  previousPage: "",
  data: [],
};

export const BLANK_COMPANIES_MINIMIZED: ICompanyMinimized = {
  id: 0,
  name: "",
  url: "",
  email: "",
  companyUsers: [],
};

export const BLANK_ADMIN_USER: IAdmin = {
  email: "",
  role: "",
};

export const BLANK_ADMIN_CONTEXT: IAdminContext = {
  user: BLANK_ADMIN_USER,
  isAdmin: false,
};

export const BLANK_IUSER: IUser = {
  firstName: "",
  lastName: "",
  email: "",
  imageUrl: "",
  phoneNumber: "",
  id: "",
  type: "",
};

export const BLANK_IUSER_LOWERCASE: IUserLowerCase = {
  ...BLANK_IUSER,
  firstname: "",
  lastname: "",
};
