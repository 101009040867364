import { PuffLoader } from "react-spinners";
import "./loader.scss";

const Loader = () => {
  return (
    <div className={`loader-container`}>
      <PuffLoader size={150} loading={true} color="#2363f6" />
      <p className="text">Loading...</p>
    </div>
  );
};

export default Loader;
