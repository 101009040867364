import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAdminContext } from "./context/AdminContext";
import NoAccess from "./pages/routeInformationPages/noAccess/NoAccess";
import NotFound from "./pages/routeInformationPages/pageNotFound/NotFound";
import { lazy, Suspense } from "react";
import Loader from "./components/Loader/Loader";

const Router = () => {
  const { CheckIfAdmin } = useAdminContext();

  const Admin = lazy(() => import("./pages/admin/Admin"));
  const Login = lazy(() => import("./pages/login/Login"));

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/" // for now
            element={<Login />}
          />
          {/* once you login here's the admin page w all options */}
          <Route
            path="/admin"
            element={
              <CheckIfAdmin redirectTo="/access-denied">
                <Admin />
              </CheckIfAdmin>
            }
          />

          <Route path="/access-denied" element={<NoAccess />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
